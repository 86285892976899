import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuInterface, MenuResponseInterface } from '../../interfaces/menu.interface';

@Injectable()
export class MenuService {
  constructor(private http: HttpClient) {}

  /**
   * @param {string} startDate ISO Date String
   * @param {string} endDate ISO Date String
   */
  getMenusForRange(
    startDate: string,
    endDate: string
  ): Observable<MenuResponseInterface[]> {
    return this.http.post<MenuResponseInterface[]>('API/menus/for-range', {
      startDate,
      endDate,
    });
  }

  getAllAvailableMenus() {
    // return this.http.get<MenuResponseInterface[]>('API/menus', {});
    return this.getMenusForRange('2020-01-01T19:45:36.990Z', '2030-12-31T19:45:36.990Z');
  }

  getMenuById(menuId: string): Observable<MenuInterface> {
    return this.http.get<MenuInterface>(`API/menus/menu/${menuId}`);
  }

  putMenu(
    soupId: string,
    dishId: string,
    date: string,
    id?: string
  ): Observable<string> {
    return this.http.put(
      'API/menus/menu',
      {
        soupId,
        dishId,
        date,
        id,
      },
      { responseType: 'text' }
    );
  }

  deleteMenu(menuId: string): Observable<void> {
    return this.http.delete<void>(`API/menus/menu/${menuId}`);
  }

}
