import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth/auth.service';
import { NavComponent } from './components/nav/nav.component';
import { RouterModule } from '@angular/router';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { AdminGuard } from './guards/admin/admin.guard';
import { CustomerGuard } from './guards/customer/customer.guard';
import { LoginRedirectComponent } from './components/login-redirect/login-redirect.component';
import { RootGuard } from './guards/root/root.guard';
import { ProfileGuard } from './guards/profile/profile.guard';
import { BackendApiInterceptor } from './interceptors/backend-api/backend-api.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HasPipe } from './pipes/has/has.pipe';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { MenuService } from './services/menu/menu.service';
import { MenuNavigatorService } from './services/menu-navigator/menu-navigator.service';
import { MealService } from './services/meal/meal.service';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { WeekNumberPipe } from './pipes/week-number.pipe';
import { DateFnsFormatPipe } from './pipes/date-fns-format.pipe';

@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule],
  providers: [
    AuthService,
    AdminGuard,
    CustomerGuard,
    RootGuard,
    ProfileGuard,
    MenuService,
    MealService,
    MenuNavigatorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendApiInterceptor,
      multi: true,
    },
  ],
  declarations: [
    NavComponent,
    WelcomeComponent,
    LoginRedirectComponent,
    HasPipe,
    PrivacyComponent,
    DateFormatPipe,
    WeekNumberPipe,
    DateFnsFormatPipe,
  ],
  exports: [
    NavComponent,
    WelcomeComponent,
    LoginRedirectComponent,
    HasPipe,
    PrivacyComponent,
    DateFormatPipe,
    WeekNumberPipe,
    DateFnsFormatPipe,
  ],
})
export class SharedModule {}
