import { Injectable } from '@angular/core';
import { startOfWeek, previousMonday, nextMonday, format, endOfWeek } from 'date-fns';
import { Observable, Subscription, shareReplay } from 'rxjs';
import { MenuResponseInterface } from '../../interfaces/menu.interface';
import { MenuService } from '../menu/menu.service';

@Injectable()
export class MenuNavigatorService {
  weeklyMenus: { [key: string]: Observable<MenuResponseInterface[]> } = {};

  private deleteSub?: Subscription;

  constructor(private menuService: MenuService,) {}

  ngOnDestroy(): void {
    this.deleteSub?.unsubscribe();
  }

  refreshData(): void {
    this.weeklyMenus = {};
  }

  loadPreviousWeek(dates: Date[]): void {
    const previousWeekMonday = previousMonday(dates[0]);
    dates.unshift(previousWeekMonday);
  }

  loadNextWeek(dates: Date[]): void {
    const nextWeekMonday = nextMonday(dates[dates.length - 1]);
    dates.push(nextWeekMonday);
  }

  getWeeklyMenuByDate(date: Date): Observable<MenuResponseInterface[]> {
    const firstDayOfTheWeek = startOfWeek(date);
    const fdotwString = format(firstDayOfTheWeek, 'yyyy-MM-dd');
    if (!this.weeklyMenus[fdotwString]) {
      const lastDayOfTheWeek = endOfWeek(date);
      const ldotwString = format(lastDayOfTheWeek, 'yyyy-MM-dd');

      this.weeklyMenus[fdotwString] = this.menuService.getMenusForRange(
        `${fdotwString}T00:00:00.077Z`,
        `${ldotwString}T23:59:59.077Z`
      ).pipe(
        shareReplay(1),
      );
    }
    return this.weeklyMenus[fdotwString];
  }
}
