import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class BackendApiInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith('API/')) {
      const url = `${environment.backendUrl}${request.url.substring(3)}`;
      const backendRequest = request.clone({ 
        url,
        setHeaders: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        }
      });
      return next.handle(backendRequest);
    }
    return next.handle(request);
  }
}
