import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MealInterface, MealType } from '../../interfaces/meal.interface';

@Injectable()
export class MealService {
  constructor(private http: HttpClient) {}

  getAllMeals(): Observable<MealInterface[]> {
    return this.http.get<MealInterface[]>('API/meals');
  }

  putMeal(title: string, type: MealType, id?: string): Observable<string> {
    return this.http.put(
      'API/meals/meal',
      {
        title,
        type,
        id,
      },
      { responseType: 'text' }
    );
  }

  getMealById(mealId: string): Observable<MealInterface> {
    return this.http.get<MealInterface>(`API/meals/meal/${mealId}`);
  }

  deleteMeal(mealId: string): Observable<void> {
    return this.http.delete<void>(`API/meals/meal/${mealId}`);
  }
}
