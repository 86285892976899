import { Pipe, PipeTransform } from '@angular/core';
import format from 'date-fns/format';

@Pipe({
  name: 'dateFnsFormat'
})
export class DateFnsFormatPipe implements PipeTransform {

  transform(value: Date | string, formatString: string): string {
    return format(typeof value === 'string' ? new Date(value) : value, formatString);
  }

}
