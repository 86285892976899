import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

@Injectable()
export class RootGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): UrlTree {
    if (this.authService.isAdmin()) {
      return this.router.createUrlTree(['admin']);
    } else if (this.authService.isCustomer()) {
      return this.router.createUrlTree(['menu']);
    }
    return this.router.createUrlTree(['welcome']);
  }
}
