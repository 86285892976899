<nav class="navbar navbar-expand-lg bg-light">
  <div class="container-fluid">
    <a class="navbar-brand">
      <img
        src="assets/gabi-konyha-logo.svg"
        style="max-height: 2em"
        routerLink="/"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item" *ngIf="authService.isCustomer$ | async">
          <a
            class="nav-link"
            routerLinkActive="active"
            routerLink="/menu"
            aria-current="page"
            i18n="@@NAV_HOME"
            >Home</a
          >
        </li>
        <ng-container *ngIf="authService.isAdmin$ | async">
          <li class="nav-item">
            <a
              class="nav-link"
              routerLinkActive="active"
              aria-current="page"
              routerLink="admin/menu"
              i18n="@@NAV_EDIT_MENUS"
              >Edit Menus</a
            >
          </li>
        </ng-container>
      </ul>
      <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
        <li class="nav-item me-auto" *ngIf="authService.isLoggedIn$ | async">
          <a class="nav-link" routerLink="profile" i18n="@@NAV_PROFILE"
            >Profile</a
          >
        </li>
        <li class="nav-item me-auto">
          <a
            *ngIf="!(authService.isLoggedIn$ | async)"
            class="nav-link"
            (click)="AuthService.oauthRedirect()"
            i18n="@@NAV_LOGIN_REGISTER"
            >Log in / Register</a
          >
          <a
            *ngIf="authService.isLoggedIn$ | async"
            class="nav-link"
            (click)="authService.logout()"
            i18n="@@NAV_LOGOUT"
            >Logout</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
