<h1>Adatvédelem és Biztonság</h1>

<p>
  A weboldalon személyes adatokat tárolunk. Személyes adataid biztonságáról
  az Amazon Cognito gondoskodik.
</p>
<p>
  Az oldalon tároljuk a vezeték és keresztneved, illetve e-mail címedet.
  Ezeket az információkat csak és kizárólag Adminok látják, kizárólag
  beazonosítás céljából, különben nem tudnánk, hogy ki vagy és a rendelésedet
  nem tudnánk, hogy hova vigyük.
</p>
<p>
  Sütiket nem tárolunk, de rendelésre szívesen készítünk nektek.
</p>

<h2>Adatok törlése</h2>
<p>
  Adataid törléséhez bejelentkezés után a profil menüt kiválasztva,
  majd a törlés gombra kattintva van lehetőség.
</p>
