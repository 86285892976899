<div class="row justify-content-center">
  <div class="col-8 col-md-6 col-lg-3 align-self-center">
    <img class="mh-100 w-100" src="assets/gabi-konyha-logo.svg" alt="logo" />
  </div>
</div>
<ng-container *ngIf="!(authService.isLoggedIn$ | async); else loggedIn">
  <div class="row justify-content-center my-3">
    <div class="col-8 col-md-6 align-self-center text-center">
      <button
        (click)="AuthService.oauthRedirect()"
        type="button"
        class="btn btn-outline-primary btn-large"
        i18n="@@NAV_LOGIN_REGISTER"
      >
        Login / Register
      </button>
    </div>
  </div>
  <div class="row justify-content-center">
    <div
      class="col-12 col-md-6 align-self-center text-center alert alert-info"
      i18n="@@NAV_TO_USE_LOG_IN_TEXT"
    >
      To use this website, please login or register
    </div>
  </div>
</ng-container>
<ng-template #loggedIn>
  <div class="row justify-content-center my-2 m-lg-5">
    <div
      class="col-12 col-md-6 align-self-center text-center alert alert-warning"
      i18n="@@NAV_PROFILE_UNDER_CHECK_TEXT"
    >
      Your profile is under checking. Please wait until it get's activated.
    </div>
  </div>
</ng-template>
