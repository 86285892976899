import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, filter, map, mergeMap, take, tap } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-login-redirect',
  templateUrl: './login-redirect.component.html',
})
export class LoginRedirectComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    this.route.fragment
      .pipe(
        filter(Boolean),
        map((value) => new URLSearchParams(value)),
        filter((value) => value.has('id_token')),
        take(1),
        map((value) => value.get('id_token') || ''),
        tap((value) => {
          window.sessionStorage.setItem('token', value);
          window.location.hash = '';
        }),
        tap(() => this.authService.parseToken()),
        mergeMap(() => this.authService.isLoggedIn$),
        filter(Boolean),
        debounceTime(0)
      )
      .subscribe(() => {
        this.router.navigate(['']);
      });
  }
}
