import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import JWT from 'jwt-decode';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AuthService {
  private token: { [key: string]: any } = {};

  private userSessionIsActive$$: BehaviorSubject<boolean>;
  public isLoggedIn$: Observable<boolean>;
  public isAdmin$: Observable<boolean>;
  public isCustomer$: Observable<boolean>;

  constructor(private router: Router) {
    this.userSessionIsActive$$ = new BehaviorSubject(false);
    this.isLoggedIn$ = this.userSessionIsActive$$.asObservable();
    this.isAdmin$ = this.userSessionIsActive$$.pipe(map(() => this.isAdmin()));
    this.isCustomer$ = this.userSessionIsActive$$.pipe(
      map(() => this.isCustomer())
    );
    this.parseToken();
  }

  static oauthRedirect(): void {
    const redirectUrl = window.location.origin;
    window.location.href = `${environment.authOrigin}/login?client_id=${environment.clientId}&response_type=token&redirect_uri=${redirectUrl}/login-redirect`;
  }

  get loggedInUsername(): string | undefined {
    return this.token['cognito:username'];
  }

  parseToken(): typeof this.token {
    try {
      this.token = JWT(window.sessionStorage.getItem('token') || '');
      if (this.isTokenExpired) {
        this.token = {};
        window.sessionStorage.removeItem('token');
        this.userSessionIsActive$$.next(false);
      } else {
        this.userSessionIsActive$$.next(true);
      }
    } catch {
      this.token = {};
      this.userSessionIsActive$$.next(false);
    }
    return this.token;
  }

  get isTokenExpired(): boolean {
    return !(
      this.token &&
      this.token['exp'] &&
      +this.token['exp'] * 1000 > Date.now()
    );
  }

  isAdmin(): boolean {
    const groups: string[] = this.token['cognito:groups'] || [];
    return groups.includes('admin');
  }

  isCustomer(): boolean {
    const groups: string[] = this.token['cognito:groups'] || [];
    return groups.includes('customer');
  }

  logout(): void {
    window.sessionStorage.removeItem('token');
    this.parseToken();
    this.router.navigate(['welcome']);
  }

  get userInfo(): any {
    return this.token;
  }
}
