import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'has'
})
export class HasPipe implements PipeTransform {
  transform(value: string[], property: string): boolean {
    return value.includes(property);
  }
}
