import { Pipe, PipeTransform } from '@angular/core';
import getWeek from 'date-fns/getWeek';

@Pipe({
  name: 'weekNumber'
})
export class WeekNumberPipe implements PipeTransform {
  transform(value: Date): number {
    return getWeek(value);
  }
}
