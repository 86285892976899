import { Pipe, PipeTransform } from '@angular/core';
import format from 'date-fns/format';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(date: string): string {
    return format(new Date(date), 'EEEE');
  }
}
